<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    has-modal-card>
    <div class="model-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="text-title" />
          </p>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <div class="field">
              <label class="label mb-4">Please Select Labour Item Type</label>
              <div class="control is-flex">
                <div v-for="(item, index) in labourItems"
                  :key="index">
                  <div class="pretty p-icon p-curve p-smooth p-bigger mr-6">
                    <input v-model="selectedLabourItemTypes"
                      type="checkbox"
                      :value="item.value">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label> {{ item.name }} </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"
          :style="`justify-content: flex-${buttonLocation}`">
          <button class="button is-primary"
            @click="add()">Add Labour Item</button>
          <button class="button"
            @click="close()">Close</button>
        </footer>
      </div>
    </div>
  </base-modal-ex>
</template>
<script>

import BaseModalEx from '@/components/BulmaModal/BaseModalEx'

export default {
  name: 'QuoteTemplateLabourTypeModal',
  components: {
    BaseModalEx
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end'
    }
  },
  data() {
    return {
      isActive: this.active || false,
      selectedLabourItemTypes: [],
      labourItems: [
        { name: 'RR', value: 'RR' },
        { name: 'REP', value: 'REP' },
        { name: 'PAINT', value: 'PAINT' },
        { name: 'MECH', value: 'MECH' }]
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  methods: {
    add() {
      this.$emit('update:selectedTypes', this.selectedLabourItemTypes)
      this.$emit('add')
      this.close()
    },
    close() {
      this.isActive = false
      this.selectedLabourItemTypes = []
      this.$emit('update:active', false)
    }
  }
}
</script>